:root {
  --mdc-checkbox-state-layer-size: 1rem;
  --viz-font: "inter-var", "Helvetica Neue", "sans-serif", "Noto Sans TC",
    "Microsoft JhengHei";
  //--viz-font: "Helvetica", "Arial","LiHei Pro","黑體-繁","微軟正黑體", sans-serif;
  //--viz-font: "Lato", "Noto Sans TC", "PingFang TC", "Helvetica Neue", "SF Pro TC", Helvetica, arial, "Microsoft JhengHei", "Microsoft YaHei", sans-serif;
}

@font-face {
  font-family: "inter-var";
  src: url("/css/fonts/inter.woff2") format("woff2");
}

/* Webfont: Lato-Black */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-Black.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-Black.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-Black.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-Black.woff") format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BlackItalic */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-BlackItalic.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-BlackItalic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-BlackItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-BlackItalic.woff") format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-BlackItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-Bold.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-Bold.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-Bold.woff") format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BoldItalic */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-BoldItalic.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-BoldItalic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-BoldItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-BoldItalic.woff") format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-BoldItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Hairline */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-Hairline.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-Hairline.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-Hairline.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-Hairline.woff") format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-Hairline.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HairlineItalic */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-HairlineItalic.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-HairlineItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-HairlineItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-HairlineItalic.woff")
      format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-HairlineItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: 100;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Heavy */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-Heavy.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-Heavy.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-Heavy.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-Heavy.woff") format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-Heavy.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HeavyItalic */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-HeavyItalic.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-HeavyItalic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-HeavyItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-HeavyItalic.woff") format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-HeavyItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Italic */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-Italic.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-Italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-Italic.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-Italic.woff") format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-Italic.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-Light.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-Light.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-Light.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-Light.woff") format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-LightItalic */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-LightItalic.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-LightItalic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-LightItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-LightItalic.woff") format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-LightItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-Medium.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-Medium.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-Medium.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-Medium.woff") format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-MediumItalic */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-MediumItalic.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-MediumItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-MediumItalic.woff")
      format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-MediumItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-Regular.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-Regular.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-Regular.woff") format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-Semibold.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-Semibold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-Semibold.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-Semibold.woff") format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-Semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-SemiboldItalic */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-SemiboldItalic.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-SemiboldItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-SemiboldItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-SemiboldItalic.woff")
      format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-SemiboldItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Thin */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-Thin.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-Thin.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-Thin.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-Thin.woff") format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-ThinItalic */
@font-face {
  font-family: "Lato";
  src: url("/css/fonts/Lato-ThinItalic.eot");
  /* IE9 Compat Modes */
  src: url("/css/fonts/Lato-ThinItalic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/Lato-ThinItalic.woff2") format("woff2"),
    /* Modern Browsers */ url("/css/fonts/Lato-ThinItalic.woff") format("woff"),
    /* Modern Browsers */ url("/css/fonts/Lato-ThinItalic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: 200;
  text-rendering: optimizeLegibility;
}

/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("/css/fonts/roboto-v20-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"),
    url("/css/fonts/roboto-v20-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/roboto-v20-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/css/fonts/roboto-v20-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("/css/fonts/roboto-v20-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/css/fonts/roboto-v20-latin-300.svg#Roboto")
      format("svg");
  /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/css/fonts/roboto-v20-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"),
    url("/css/fonts/roboto-v20-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/roboto-v20-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("/css/fonts/roboto-v20-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("/css/fonts/roboto-v20-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/css/fonts/roboto-v20-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("/css/fonts/roboto-v20-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("/css/fonts/roboto-v20-latin-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/roboto-v20-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/css/fonts/roboto-v20-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("/css/fonts/roboto-v20-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/css/fonts/roboto-v20-latin-500.svg#Roboto")
      format("svg");
  /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("/css/fonts/roboto-v20-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("/css/fonts/roboto-v20-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/roboto-v20-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/css/fonts/roboto-v20-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("/css/fonts/roboto-v20-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/css/fonts/roboto-v20-latin-700.svg#Roboto")
      format("svg");
  /* Legacy iOS */
}

/* roboto-900 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("/css/fonts/roboto-v20-latin-900.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Black"), local("Roboto-Black"),
    url("/css/fonts/roboto-v20-latin-900.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/css/fonts/roboto-v20-latin-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/css/fonts/roboto-v20-latin-900.woff")
      format("woff"),
    /* Modern Browsers */ url("/css/fonts/roboto-v20-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/css/fonts/roboto-v20-latin-900.svg#Roboto")
      format("svg");
  /* Legacy iOS */
}

body {
  // font-family: Roboto, "Helvetica Neue", sans-serif !important;
  // font-family: "Lato", "Helvetica Neue", sans-serif !important;
  font-family: var(--viz-font) !important;

  font-size: 14px;
}

////////////////////////
// mixin
////////////////////////
@mixin box_shadow() {
  //box-shadow: 1px 1px 10px 0px rgb(0 0 0 / 10%);
  box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 10%);
  background-color: white;
}

///////////////////////////
// HTML customization

a:link {
  color: #0080c6;
}

a:visited {
  color: #0080c6;
}

/* Scroll Bar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 5px;

  &:hover {
    background: #aeaeae;
  }
}

hr {
  border: 0;
  //height: 0;
  //border-bottom: 1px solid #e4e4e4;
  height: 1px;
  background: #e4e4e4;
  width: 99% !important;
  margin-top: 1em;
  margin-bottom: 1em;
}

//////////////////////////////////////////////////////////////////////////////
///
///
///  MAterial Customizationms
///
///
//////////////////////////////////////////////////////////////////////////////
/* Tabel */
tr.mat-mdc-header-row {
  height: 45px !important;
}

th:first-child {
  padding-left: 15px !important;
}

td:first-child {
  padding-left: 15px !important;
}

th:last-child {
  padding-right: 15px !important;
}

td:last-child {
  padding-right: 15px !important;
}

.mat-sort-header-arrow {
  margin: 0 0 0 2px;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0 0.25rem 0 0.25rem !important;
}

/* mat tools */
.mat-toolbar-single-row {
  height: 50px !important;
  flex: 0 0 50px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);

  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }
}

.mat-tab-body-content {
  margin: 0px !important;
}

// font color
.mat-mdc-dialog-container .mdc-dialog__content {
  color: #000000 !important;
}

.mat-mdc-row {
  color: #000000 !important;
}

// date range picker
.mat-datepicker-toggle {
  .mdc-icon-button {
    border-radius: 3px;
    color: #1ca0da;
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: flex;
    padding: 0px;
    justify-content: center;
    align-items: center;
  }

  .mat-icon-button {
    border-radius: 3px;
    color: #1ca0da;
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: center;
  }
}

//checkbox
.mat-checkbox-layout {
  margin-right: 10px;
}

.mat-checkbox-inner-container {
  margin-right: 3px !important;
}

.mat-checkbox-inner-container-no-side-margin {
  margin-right: 5px !important;
}

.mdc-checkbox__background {
  height: 1rem !important;
  width: 1rem !important;
}

.mat-mdc-checkbox-touch-target {
  height: 1rem !important;
  width: 1rem !important;
}

.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
  width: 1rem !important;
  height: 1rem !important;
  border-radius: 2px !important;
}

//tags
.mat-chip-trailing-icon {
  display: flex;
  align-items: center;
}

//form fields
// removed, cut the title in half if present
// .mat-form-field {
//   line-height: 1em !important;
// }

.mat-form-field-outline {
  background: white;
  border-radius: 5px;
}

.mat-form-field-suffix {
  top: 0px !important;
}

.mat-form-field-prefix {
  top: 0px !important;
}

.mat-form-field-appearance-outline {
  .mat-form-field-wrapper {
    padding-bottom: 10px;
  }

  .mat-form-field-infix {
    padding: 0.625em 0px;
  }

  .suffixText {
    position: relative;
    bottom: 5px;
    color: #707070;
  }

  .mat-form-field-outline-thick {
    border-width: 1px !important;
  }

  .mat-select-arrow-wrapper {
    transform: none !important;
  }
}

//NEW form fields app-form-field override
.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {
    background: white;
  }

  .mat-mdc-form-field-infix {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    min-height: 0;
  }
}

.mat-mdc-form-field-subscript-wrapper {
  height: 10px;
}

.mat-error {
  margin-left: -5px;
}

//buttons
.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  min-width: 10px !important;
  padding: 0 13px !important;
}

.mat-card {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  border: 1px solid #f0f0f0;
}

//tabs
.mat-tab-label {
  min-width: 100px !important;
  height: 40px;
}

.mat-tab-link {
  padding: 0 15px !important;
  min-width: 100px !important;
}

.mat-ink-bar {
  min-width: 100px !important;
}

//popup menu
.mat-menu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

///////////////////////////
// material customization modifiers
.readonly {
  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: transparent;
    transition: none !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    background: #f5f5f5;
    border-radius: 6px;
    color: transparent;
  }
}

.mat-form-field-nopadding {
  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }
}

.mat-form-field-nopadding-fill {
  .mat-form-field-flex {
    padding: 0 0.75em 0 0.75em !important;
  }

  .mat-form-field-wrapper {
    margin-bottom: -1.34375em;
  }
}

////////////////////////
// common style setting
////////////////////////

//date field
// .dateField_width {
//   width: 150px;
//   font-size: 12px;
//   margin-right: 10px;
//   .mat-form-field-suffix {
//     top: 0.25em !important;
//   }
// }
// .multi-dateField_width {
//   width: 200px;
//   font-size: 12px;
//   margin-right: 10px;
//   .mat-form-field-suffix {
//     top: 0.25em !important;
//   }
// }

// .timeField_width {
//   width: 90px;
// }

////////////////////////////////////////////
// to be replaced by custom_butons
////////////////////////////////////////////

/* buttons */
// .squareButton {
//   height: 39px;
//   min-width: 19px;
//   width: fit-content;
//   padding: 0 10px;
//   color: #039be5;
//   //background: #039be5;
//   border-radius: 5px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 14px;
//   cursor: pointer;
//   &:hover {
//     color: #0277bd;
//   }
// }

// .squareButton2 {
//   height: 39px;
//   min-width: 19px;
//   width: fit-content;
//   padding: 0 10px;
//   border-radius: 5px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 14px;
//   cursor: pointer;
//   color: #707070;
//   &:hover {
//     background: #f0f0f0;
//     color: #0277bd;
//   }
// }

// .squareButton_bgBlue {
//   height: 39px;
//   min-width: 19px;
//   width: fit-content;
//   padding: 0 10px;
//   color: white;
//   background: #039be5;
//   border-radius: 5px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 14px;
//   cursor: pointer;
//   &:hover {
//     background: #0277bd;
//   }

//   div {
//     padding: 0 5px;
//   }
// }

// .squareButton_bgGray {
//   height: 39px;
//   min-width: 19px;
//   width: fit-content;
//   padding: 0 10px;
//   color: #707070;
//   background: #eee;
//   border-radius: 5px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 14px;
//   cursor: pointer;

//   &:hover {
//     color: #0277bd;
//   }
// }

//text only
// .text-link link-grey {
//   cursor: pointer;
//   font-size: 14px;
//   color: #707070;

//   &:hover {
//     color: #0277bd;
//     text-decoration: underline;
//   }
// }

// .text-link link-blue {
//   cursor: pointer;
//   font-size: 14px;
//   color: #0277bd;

//   &:hover {
//     //color: #0277bd;
//     text-decoration: underline;
//   }
// }

//slige toggle to be removed
.slide_toggle {
  display: flex !important;
  flex-direction: column;

  .active {
    color: #409e4c;
  }

  .inactive {
    color: #707070;
  }
}

/* form field styles */
// .combined_field .mat-form-field-infix {
//   display: inherit;
//   .select_field {
//     background: #eee;
//     width: 80px;
//     position: absolute;
//     padding: 12px;
//     top: -7px;
//     right: -9px;
//     border-top-right-radius: 5px;
//     border-bottom-right-radius: 5px;
//   }
// }

// .alt_dropDown {
//   display: flex;
//   align-items: center;
//   font-size: 0.9rem;
//   font-weight: bold;
//   background: #eee;
//   padding: 0 10px 0 10px;
//   height: 39px;
//   border-radius: 5px;
//   cursor: pointer;
//   min-width: 130px;
//   max-width: 300px;
//   overflow: hidden;
//   &:hover {
//     filter: brightness(95%);
//   }
//   .icon {
//     color: #707070;
//     padding: 0 5px;
//   }
// }

// .custom_field {
//   border: 1px solid #dbdbdb;
//   border-radius: 5px;
//   display: flex;
//   height: 37px;
//   align-items: center;
//   padding: 0 10px;
//   margin-right: 10px;

//   &.custom_date_field {
//     width: 300px !important;
//     padding: 0 0 0 10px;
//     .mat-select {
//       width: 100px;
//       margin-right: 10px;
//     }
//     .mat-form-field-infix {
//       display: inherit;
//       .select_date {
//         font-size: 12px;
//         background: #eee;
//         width: 150px;
//         position: absolute;
//         padding: 12px;
//         top: -7px;
//         right: -9px;
//         border-top-right-radius: 5px;
//         border-bottom-right-radius: 5px;
//       }
//     }
//   }

//   .field_icon {
//     color: #707070;
//     margin-right: 5px;
//     display: flex;
//   }
// }

////////////////////////
// tools
////////////////////////
// .flex_alignItem {
//   display: flex;
//   align-items: center;
// }

// should remove all of these eventually

.margin_right {
  margin-right: 10px;
}

.margin_top {
  margin-top: 1em;
}

.margin_top_adjust {
  margin-top: -11px;
}

.margin_bottom {
  margin-bottom: 4em;
}

.field_label {
  margin: 2px;
  font-weight: 500;
  display: flex;
  gap: 5px;
  align-items: baseline;
}

.subtitle {
  margin-bottom: 0.8rem;
  color: #707070;
  font-weight: 500;
}

//text
.text_ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// .max-height-container {
//   display: flex;
//   flex-direction: column;
//   overflow: hidden;
// }
// .row-max-height {
//   display: flex;
//   flex-direction: column;
//   overflow: hidden;
// }

// .flex-column {
//   display: flex;
//   flex-direction: column;
// }

// background no scroll

// to be moved to component.scss
.noscroll {
  overflow: hidden;
}

.max-height {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  &.mat-mdc-dialog-content {
    display: flex !important;
  }
}

.spacer {
  flex: 1 1 auto;
}

.max-scroll {
  flex: 1 1 auto;
  overflow: auto;
}

//////////

.people-avatar {
  width: 30px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: white;
  border-radius: 15px;
  display: inline-block;
  border: 1px solid white;
}

// .cavatar {
//   display: inline-flex;
//   flex-direction: column;

//   .icon {
//     height: 10px;
//     line-height: 10px;
//     font-size: 10px;
//   }

//   .ctitle {
//     height: 18px;
//     line-height: 18px;
//   }
// }

// .people-avatar-image {
//   border-radius: 50%;
//   width: 30px;
//   min-width: 30px;
//   height: 30px;
//   border: 1px solid white;
//   margin-top: 2px;
// }

// use size modifier
// .people-circle2 {
//   width: 25px;
//   min-width: 25px;
//   height: 25px;
//   line-height: 23px;
//   text-align: center;
//   font-weight: 300;
//   color: white;
//   border-radius: 15px;
//   display: inline-block;
//   font-size: 8px;
//   margin-left: -3px;
//   transform: scale(0.7);
//   cursor: default;
//   &:first-child {
//     margin-left: 0px;
//   }
// }

//Instructions

.page-content {
  padding: 0 5px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  //margin-bottom: 3.5em;
}

.page-button_bar {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: absolute;
  bottom: 0;
  right: 0;

  button {
    margin-right: 5px;
  }
}

////UI???
// .custom_select {
//   width: 100%;
//   border: 1px solid #e2eded;
//   border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
//   border-radius: 10px;
//   padding: 0.8rem 1rem;

//   .mat-form-field-wrapper {
//     padding-bottom: 0px !important;
//   }
//   .mat-form-field-infix {
//     padding: 0px !important;
//     border-top: none;
//   }
// }

// absorb into app-badge
// .custom_label {
//   border-radius: 5px;
//   font-size: smaller;
//   padding: 5px;
//   //margin-left: 10px;
//   display: flex;
//   width: fit-content;
//   align-items: center;
//   justify-content: center;

//   &.orange {
//     background: #fcedb7;
//     color: #fe9a02;
//   }

//   &.blue {
//     background: #bae2f4;
//     color: #1ca0da;
//   }

//   &.green {
//     background: #409e4c;
//     color: white;
//   }
// }

// .underlineText {
//   cursor: pointer;
//   &:hover {
//     text-decoration: underline;
//   }
// }

// .clickable {
//   cursor: pointer;
//   &:hover {
//     background-color: #eee;
//   }
// }

.slide_toggle {
  display: flex !important;
  flex-direction: column;

  .active {
    color: #409e4c;
  }

  .inactive {
    color: #707070;
  }
}

///////////////////////////////////////////////////////////////////////////
///
///
///  Search Bar
///
///////////////////////////////////////////////////////////////////////////

.ctlSearchBar {
  padding: 10px 10px 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  //font-size: 14px;

  button {
    margin-right: 5px;
  }

  .mat-form-field {
    margin-right: 10px;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: 6px 0px !important;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0px !important;
    }
  }

  .text {
    margin-right: 10px;
    font-size: small;
  }

  .searchField {
    display: flex;
    align-items: center;
    background: #e4e4e4;
    border-radius: 5px;
    overflow: hidden;
    padding-top: 2px;

    input {
      border: none;
      background: transparent;
      width: 100%;

      :focus {
        border: none;
      }
    }

    .searchIcon {
      padding: 10px;
    }

    .clearSearch {
      padding: 10px;
      cursor: pointer;
      color: #707070;
    }
  }

  .rowCount {
    color: var(--viz-brown);
  }
}

.NgxMatSelectSearch {
  .mat-icon-button {
    line-height: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
  }
}

// .ctlSettingCard {
//   margin: 10px;
//   padding: 10px;

//   .header {
//     display: flex;
//     align-items: center;
//     background: white;
//     margin: -10px -10px 10px -10px;
//     padding: 10px;
//     border-bottom: 1px solid #eee;
//   }
//   .title {
//     font-weight: bold;
//     font-size: 18px;
//     display: flex;
//   }
//   .title_small {
//     font-weight: bold;
//   }

//   .item {
//     display: flex;
//     align-items: center;
//     margin-bottom: 10px;
//     .item_title {
//       width: 200px;
//     }
//   }

//   //override style

//   .imagePreview {
//     object-fit: contain !important;
//   }
// }

.closeButton {
  cursor: pointer;
  margin-top: -5px;
  position: absolute;
  right: 0px;
  top: 1px;
  z-index: 10000;
  font-size: 20px;
  //color: #707070;
  color: #1ca0da;

  &:hover {
    //color: #1a1818;
    filter: brightness(0.5);
  }
}

//#######################
// should DEPRECATE
.dialog-max {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  &.tabDialog {
    .mat-dialog-content {
      padding-bottom: 0 !important;
    }
  }

  &.material-max {
    width: calc(80vw - 40px);
    height: 95vh;
  }

  // Material hacks
  &.full-width-dialog {
    max-width: 100vw !important;
    max-height: 100vh !important;
  }

  .mat-dialog-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    max-height: none;
    position: relative;
    overflow: hidden;
    padding-bottom: 3.5em;
  }

  .tabCloseButton {
    cursor: pointer;
    margin-top: -5px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10000;
    //color: #707070;
    color: #1ca0da;
    font-size: 20px;

    &:hover {
      //color: #1a1818;
      filter: brightness(0.5);
    }
  }

  .mat-tab-group {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .mat-tab-body-wrapper {
      flex: 1 1 auto;
    }

    .mat-tab-body {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .mat-tab-body-content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .dialog-max-tab-content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  // .mat-stepper-horizontal {
  //   display: flex;
  //   flex-direction: column;
  //   .mat-horizontal-stepper-wrapper {
  //     flex: 1 1 auto;
  //   }
  //   .mat-horizontal-content-container {
  //     flex: 1 1 auto;
  //     padding: 10px !important;
  //     .mat-horizontal-stepper-content {
  //       height: 100%;
  //     }
  //     .mat-horizontal-stepper-content[aria-expanded="false"] {
  //       display: none;
  //     }
  //   }
  // }

  .dialog-content {
    font-size: 18px;
    text-align: center;
  }

  .mat-dialog-title {
    font-size: 18px;
    // flex: 1 1 auto;
    margin-bottom: -2px;
  }

  .mat-dialog-actions {
    margin-bottom: 0px;
    padding: 0px;
    min-height: fit-content;
  }
}

// customize dialog-max?
.dialog-max {
  .closeButton {
    cursor: pointer;
    margin: 0.5rem 1rem;
    margin-top: 0px;
  }
}

////////
/// not sure the purpose
// .dialog-no-button-bar {
//   .mat-dialog-content {
//     padding-bottom: 0 !important;
//   }
// }

// should be replaced by dialogBar

.button-bar {
  padding-bottom: 5px !important;
  padding-top: 5px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  border-top: 1px solid #eee;

  .mat-stroked-button {
    background: white;
  }

  button,
  a {
    margin-right: 5px;
  }
}

// .explaination {
//   font-size: 12px;
//   color: #aaa;
// }

////////////////////////
// Navigation
////////////////////////

.sidenav_logo {
  display: flex;
  justify-content: center;
  align-items: center;

  .app-logo {
    max-height: 60px;
    max-width: 180px;
    margin: 10px;
  }
}

.ctlUploadContainer {
  .uploadArea {
    height: 100px;
    width: 80%;
    margin: 20px auto;
    border: 2px dashed #1c8adb;
    border-radius: 10px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      background-color: #9ecbec !important;
      opacity: 0.8;
    }

    .text {
      flex: 1 1 auto;
      text-align: center;
      color: #707070;
      font-size: 25px;
    }
  }

  .files-list {
    display: flex;
    justify-content: space-between;
    margin: 5px auto;
    background: #ffffff;
    padding: 5px;
    color: #1c8adb;
    margin-left: 10px;

    .delete-file {
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
}

.ctlUpload {
  text-align: center;
  height: 100px;
  width: 95%;
  margin: 10px auto;
  border: 2px dashed #1c8adb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #707070;
  cursor: pointer;
}

.ctlTags {
  display: flex;
  align-items: center;
  margin-top: 5px;
  flex-wrap: wrap;

  > div {
    background-color: #f0f0f0;
    color: #777;
    border-radius: 4px;
    padding: 2px 5px;
    font-size: 0.8em;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    margin: 2px 3px 0 0;
  }
}

// .custom_button {
//   height: 37px;
//   min-width: 37px;
//   border-radius: 5px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 14px;
//   cursor: pointer;

//   //origin: addNew_btn
//   &.blue {
//     background: #039be5;
//     color: white;

//     &:hover {
//       background: #0277bd;
//     }
//   }

//   &.light_blue {
//     color: #1ca0da;
//     background: #e7f5fb;

//     &:hover {
//       background: #a6e0f8;
//     }
//   }

//origin: squareButton
// &.noBg_blue {
//   color: #039be5;

//   &:hover {
//     color: #0277bd;
//   }
// }

//origin: squareButton
// &.noBg_grey {
//   color: #707070;

//   &:hover {
//     background: #f0f0f0;
//     color: #0277bd;
//   }
// }

//origin: roundButton
// &.round_blue {
//   color: #039be5;
//   font-size: 20px;
//   border-radius: 30px;

//   &:hover {
//     color: #0277bd;
//   }
// }

//origin: roundButton2
//   &.round_grey {
//     font-size: 20px;
//     border-radius: 30px;
//     color: #0277bd;
//     background: #eee;
//   }
// }

// .text_link {
//   cursor: pointer;
//   font-size: 14px;
//   &:hover {
//     text-decoration: underline;
//   }
//   &.selected {
//     font-weight: bold;
//     text-decoration: underline;
//     color: #1ca0da;
//   }

//   &_grey {
//     @extend .text_link;
//     color: #707070;
//     &:hover {
//       text-decoration: underline;
//     }
//   }
//   &_blue {
//     @extend .text_link;
//     color: #0277bd;
//   }
// }

/////////////////////////////////////////////////////////////////
///
/// cdialog
///
/////////////////////////////////////////////////////////////////

.full-dialog {
  max-width: 100vw !important;
  height: auto !important;
}

.mat-mdc-dialog-container {
  .cdialog {
    //mdc needs to be 0
    margin: 0px;
  }
}

.cdialog {
  $bar-height: 48px;
  $bar-height-padding: 58px;

  margin: -24px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  max-height: 95vh;

  &.cmax {
    width: 80vw;
    height: 80vh;
  }

  &.cmax90 {
    // requires full-dialog
    width: 90vw;
    height: 90vh;
  }
  &.cmax100 {
    // requires full-dialog
    width: 100vw;
    height: 100vh;
  }

  .mat-tab-group {
    .mat-tab-body {
      padding: 0px;
    }
  }

  .mat-mdc-dialog-content,
  .mat-dialog-content {
    padding-bottom: 0px;
    flex: 1 1 auto;
  }

  .mat-mdc-dialog-content {
    padding: 0px !important;
    max-height: none !important;
  }

  ///
  .mat-mdc-tab-group {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }

  .mat-mdc-tab-group,
  .mat-tab-group {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .mat-mdc-tab-body-wrapper,
    .mat-tab-body-wrapper {
      flex: 1 1 auto;
    }

    .mat-mdc-tab-body,
    .mat-tab-body {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .mat-mdc-tab-body-content,
    .mat-tab-body-content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .dialog-max-tab-content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .mat-stepper-horizontal {
    display: flex;
    flex-direction: column;

    .mat-horizontal-stepper-wrapper {
      flex: 1 1 auto;
    }

    .mat-horizontal-content-container {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;

      .mat-horizontal-stepper-content {
        height: 98%;

        &.mat-horizontal-stepper-content-inactive {
          display: none;
        }
      }

      //     .mat-horizontal-stepper-content[aria-expanded="false"] {
      //       display: none;
      //     }
    }
  }

  .dialogHeader {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    // padding: 1rem;
    padding: 0px 1rem;

    .closeButton {
      position: relative;
      margin: 0px;
    }

    .dialogHeader_description {
      color: #707070;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0px !important;
    }

    .mdc-dialog__title {
      padding: 10px 24px 10px 0px;
      max-width: 62vw;
    }

    .mdc-dialog__title::before {
      height: 0px;
    }

    &.tab {
      position: absolute;
      top: 0px;
      right: 0px;
      left: 0px;
      border: none;
    }
  }

  .dialogBody {
    padding: 10px 10px 3rem 10px;
    height: 100%;

    .bg {
      background: #f6f6f6;
    }

    ul {
      list-style: inside;
    }
  }

  .dialogBar {
    padding: 5px;
    height: $bar-height;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    border-top: 1px solid #eee;
    z-index: 999;

    .mat-stroked-button {
      background: white;
    }

    button,
    a {
      margin-right: 5px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }


  &.full-width-dialog {
    max-width: 100vw !important;
    max-height: 100vh !important;
  }

  // cdialog options
  &.material-max {
    width: calc(80vw - 40px);
    height: 95vh;
  }

  // no buttons at the bottom
  &.full {
    .dialogBody {
      padding: 10px;
    }
  }

  // buttons at the bottom overlays on top of content
  &.overlay {
    // prevent hiding when code is not correct
    form.dialogBody {
      margin-bottom: 48;
    }

    .dialogBody {
      padding-bottom: $bar-height-padding;
    }

    .dialogBar {
      position: absolute;
      bottom: 0px;
      right: 0px;
      left: 0px;
      background: rgba(255, 255, 255, 0.8);
      width: 100%;
      z-index: 500;
    }
  }
  &.dark {
    background-color: #333;
    .card {
      background-color: black;
      color: white;
    }
  }
}

///////////////////////////////////////////////////////////////
///
///  Filter on listing page
///
///////////////////////////////////////////////////////////////

// filter section popup from the right
.ctlFilter {
  &.listFilter {
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 0px;
    right: 0px;
    width: 300px;
    bottom: 0px;
    /* height: 100vh; */
    background-color: white;
    z-index: 2000;
    //border-left: 1px solid #ccc;
    //border-top: 1px solid #ccc;
    border: #eee;
    box-shadow: -10px 2px 10px 0px rgb(0 0 0 / 10%);
  }

  .header {
    background: #eee;
    //border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    padding: 0 10px;
    min-height: 48px;

    .title {
      font-size: 1.4em;
    }
  }

  .body {
    flex: 1 1 auto;
    overflow: auto;

    .sectionHeader {
      display: flex;
      //font-size: 1.2em;
      border-bottom: 1px solid #eee;
      padding: 15px 10px;
      //margin-top: 10px;
      cursor: pointer;
    }

    .sectionBody {
      //padding: 10px;
      background: #f7fdff;
      &.padded {
        padding: 10px;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    border-top: 1px solid #eee;
    padding: 10px;
    margin-left: 6px;

    .button {
      color: #1ca0da;
      cursor: pointer;

      //font-size: 1.2em;
      //font-weight: 200;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .buttons {
    //padding: 10px 10px;
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    font-weight: bold;

    .clear_button {
      width: 100%;
      line-height: 2.5rem;

      &:hover {
        filter: brightness(0.9);
      }
    }

    .apply_button {
      width: 70%;
      background: #1ca0da;
      color: white;
      line-height: 2.5rem;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }
}

// to be removed
// .ctlFilterButtons {
//   display: flex;
//   align-items: center;
//   margin-left: 5px;
//   margin-right: 5px;
//   .info {
//     font-weight: bold;
//     padding: 0px 10px;
//     white-space: nowrap;
//     // display: none;
//   }
// }

// filter chips on listing pages
.ctlFilterChips {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  width: max-content;
  font-size: smaller;

  // replaced by app-chip
  // .chip {
  //   padding: 5px 10px;
  //   margin-right: 5px;
  //   display: flex;
  //   align-items: center;
  //   background-color: #eee;
  //   border-radius: 20px;

  //   > div {
  //     margin-right: 10px;
  //     &:last-child {
  //       margin-right: 0px;
  //     }
  //   }
  // }
}

// should move it under ctlSearchBar??

// search bar buttons
.ctlButtons {
  display: flex;
  align-items: stretch;
  border: 1px solid #eee;
  border-radius: 5px;
  background: white;

  //margin-right: 10px;

  font-weight: normal;
  font-size: 0.8rem;

  > div {
    border-left: 1px solid #eee;
    padding: 10px;
  }

  > * {
    border-left: 1px solid #eee;
    vertical-align: middle;
    display: flex;
    align-items: center;

    > div:first-child {
      padding: 10px;
    }

    cursor: pointer;

    &:hover {
      background: #e0f8ff;
      color: #1ca0da;
    }

    &.disabled {
      color: #ccc;
      pointer-events: none;
    }

    &.selected {
      background: #1ca0da;
      color: white;
      font-weight: bold;
    }
  }

  > *:first-child {
    border: none;

    &:hover {
      background: inherit;
      color: inherit;
    }
  }

  .info {
    font-weight: bold;
  }

  .mat-menu-panel {
    margin-top: 11px;
    margin-left: -48px;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////

.card-shadow {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 10px;
  border-radius: 5px;
  @include box_shadow();
}

.card {
  border: 1px solid #eee;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.1);
  background-color: white;

  // .custom_button {
  //   height: 30px;
  //   min-width: 30px;
  //   font-size: 12px;
  // }

  // sort out duplicate with sectionTitle
  .cardHeader {
    display: flex;
    align-items: center;
    padding: 20px 20px 0px 20px;
    font-size: 1.2rem;
    color: #1a1818;
    font-weight: bold;
    min-height: 30px;

    &.bg {
      // background: #f7fdff;
      background: #ccc1;
    }

    // not necessary? used in page editor
    &.small {
      font-size: 0.95rem;
    }

    // div -> div inside header
    // .explaination {
    //   margin-top: 5px;
    //   margin-bottom: 5px;
    //   font-size: 12px;
    //   font-weight: normal;
    // }
  }

  .cardFooter {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    font-size: 1rem;

    &.bg {
      background: #ccc1;
    }
  }

  .cardDivider {
    height: 1;
    border-top: 1px solid #ccc;
  }

  .cardVDivider {
    width: 1;
    border-right: 1px solid #ccc;
  }

  .cardBody {
    padding: 20px;

    // what is these
    .cardBodytitle {
      font-size: 1rem;
      margin: 10px 0;
      font-weight: 500;
    }

    .subContent {
      background: #f0f0f0;
      border-radius: 5px;
      margin-top: 10px;
      padding: 5px 1rem;
    }
  }

  .cardLine {
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    padding: 10px;
    //height: 2rem;
    cursor: pointer;

    &:hover {
      background: #f9f9f9;
    }

    &:last-child {
      border-bottom: none;
    }

    &.selected {
      background-color: #eee;
    }
  }

  .cardBorder {
    border-bottom: 1px solid #eee;
  }

  &.bg {
    background: #ccc1;
  }


  &.tight {
    .cardHeader {
      padding: 10px 10px 0px 10px;

    }
    .cardBody {
      padding: 10px;
    }
  }
  &.no-padding {
    .cardBody {
      padding: 0px;
    }
  }

  &.flex-col {
    .cardBody {
      display: flex;
      flex-direction: column;
    }
  }

  &.flex-row {
    .cardBody {
      display: flex;
      flex-direction: row;
    }
  }
}

// used to be inside ctlSettings
.settingCard {
  display: flex;

  .settingCardHeader {
    flex: 0 0 27%;
    padding: 1rem;
    padding-top: 1.5rem;

    .title {
      font-size: larger;
      margin-bottom: 1em;
      font-weight: bold;
    }

    .text {
      font-weight: 400;
      color: #707070;
      //margin-bottom: 1em;
    }
  }

  .settingCardBody {
    flex: 1 1 auto;
  }
}

.ctlSettingsContainer {
  min-width: 900px;
  width: calc(100vw - 500px);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

// there are many linkage to in the code, should totally eliminate this section
// eliminate section as it should be redundant
.ctlSettings {
  .container {
    min-width: 900px;
    width: calc(100vw - 500px);
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  // common to both card and section
  .card,
  .section {
    border: 1px solid #eee;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    @include box_shadow();

    &.selected {
      background-color: #ddd;
    }
  }

  .section {
    margin: 10px;
    flex: 1 1 auto;

    .sectionHeader {
      display: flex;
      align-items: center;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 1rem 1rem 0.5rem 1rem;
      border-bottom: 1px solid #eee;
      font-size: 1rem;
      color: #1a1818;
      font-weight: bold;
      background: #ccc1;

      .sectionButton {
        cursor: pointer;
        font-weight: normal;
        font-weight: normal;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .sectionBody {
      padding: 1rem;

      .subContent {
        background: #f0f0f0;
        border-radius: 5px;
        margin-top: 10px;
        padding: 5px 1rem;
      }
    }

    .sectionBorder {
      border-bottom: 1px solid #eee;
    }
  }

  //
  .card {
    //override
    .custom_button {
      height: 30px;
      min-width: 30px;
      font-size: 12px;
    }

    // sort out duplicate with sectionTitle
    .cardHeader {
      display: flex;
      align-items: center;
      padding: 20px 20px 5px 20px;
      font-size: 1.2rem;
      color: #1a1818;
      font-weight: bold;
      min-height: 30px;

      &.small {
        font-size: 0.95rem;
      }

      // div -> div inside header
      // .explaination {
      //   margin-top: 5px;
      //   margin-bottom: 5px;
      //   font-size: 12px;
      // }
    }

    .cardDivider {
      height: 1;
      border-top: 1px solid #ccc;
    }

    .cardBody {
      padding: 20px;

      .cardBodytitle {
        font-size: 1rem;
        margin: 10px 0;
        font-weight: 500;
      }

      .subContent {
        background: #f0f0f0;
        border-radius: 5px;
        margin-top: 10px;
        padding: 5px 1rem;
      }
    }

    .cardBorder {
      border-bottom: 1px solid #eee;
    }

    &.no-padding {
      .cardBody {
        padding: 0px;
      }
    }
  }

  .warning {
    border: 1px solid #eee;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include box_shadow();
    //background-color: #d2a56d;
    padding: 10px 20px;
    display: flex;
    font-size: 16px;
  }

  // move to app-form-field
  .priceField {
    width: 8rem;
    min-width: 8rem;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  // .explaination {
  //   font-size: 12px;
  //   color: #999;
  //   //font-weight: 300;
  // }
  .header {
    font-size: 1.1em;
    color: #1a1818;
    font-weight: 500;
  }

  .text {
  }

  .item {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    min-height: 30px;

    .title,
    .item_title {
      min-width: 200px;
      display: flex;
      flex-direction: column;
    }
  }

  .setting_subtitle {
    margin: 1rem 0 5px 0;
    font-weight: bold;
    text-decoration: underline;
  }

  // small number of places
  .line {
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    padding: 10px;
    //height: 2rem;
    cursor: pointer;

    &:hover {
      background: #f9f9f9;
    }

    &:last-child {
      border-bottom: none;
    }

    &.selected {
      background-color: #eee;
    }
  }
}

.ctlSidebarLayout {
  flex-direction: row !important;
  display: flex;
  //margin-top: 1em;
  overflow: visible !important;
  height: auto;
  padding: 10px;

  .sidebar {
    flex: 0 0 300px;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  }

  .sidebarRight {
    margin-right: 0px;
    margin-left: 10px;
  }

  .sidebarBody {
    flex: 1 1 auto;
    width: 60%;
  }
}

.ctlItemDrag {
  .dragItem {
    padding: 5px;

    .itemBody {
      min-height: 3em;
      min-width: 120px;
      //padding: 2px 10px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      background: white;
      border: 1px solid #e2eded;
      border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
      border-radius: 5px;

      .grip {
        font-size: 18px;
        cursor: grab;
        color: #707070;
        padding: 0 10px;
      }

      .itemButtons {
        display: flex;
        align-items: center;
        width: 70px;
      }

      .itemContent {
        padding: 2px;
        display: flex;
        align-items: center;
        flex: 1 1 auto;
      }

      .removeButton {
        width: 1.5rem;
        background: #f0f0f0;
        color: #707070;
        line-height: 3em;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: #ccc;
        }
      }
    }

    .draggable_area {
      cursor: grab;
    }
  }

  &:last-child {
    .dragItem {
      .itemBody {
        //border-bottom: none;
      }
    }
  }
}

.ItemDragPreview {
  display: flex;
  align-items: center;

  .grip {
    font-size: 18px;
    //padding: 0 10px;
    color: #707070;
    margin-right: 10px;
  }
}

///////////////////////////
// hacks
///////////////////////////
.ckeditor-content {
  list-style: auto !important;

  ol {
    padding: 1rem;
    list-style: auto;
  }

  ul {
    padding: 1rem;
    list-style: disc;
  }
}

.app-hover {
  &:hover {
    background-color: #f9f9f9;
  }
}
