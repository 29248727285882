/** @format */

@use "@angular/material" as mat;
@import "@angular/material/prebuilt-themes/indigo-pink.css";

@include mat.core();
$my-app-primary: mat.define-palette(mat.$grey-palette, 900);
$my-app-accent: mat.define-palette(mat.$green-palette, 700);
$my-app-warn: mat.define-palette(mat.$red-palette);
$my-app-typography: mat.define-legacy-typography-config(
  $font-family:
    "Noto Sans TC, inter-var, Helvetica Neue, sans-serif, Microsoft JhengHei",
);
$my-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
      warn: $my-app-warn,
    ),
    typography: $my-app-typography,
  )
);
@include mat.all-component-themes($my-app-theme);

$client-text-color: #212529;
$client-theme-color: #212529;

@tailwind base;
@tailwind components;
@tailwind utilities;

// tailwind and material fixes
.mdc-notched-outline__notch {
  border-right: none !important;
}

/* angular-cli file: src/styles.css */
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../../../../thinkretail/css/common2.scss";
@import "../../../../thinkretail/css/nav.scss";

@import "../../../../thinkretail/css/component.scss";

body {
  position:fixed;
  width: 100vw;
  // height: 100vh;
  height:100%;
  font-family: "Noto Sans TC,inter-var, Helvetica Neue, sans-serif";

  color: #212529;
}

.desktop_hide {
  @media screen and (min-width: $mobile-width) {
    display: none !important;
  }
}

.mobile_hide {
  @media screen and (max-width: $mobile-width) {
    display: none !important;
  }
}

a {
  color: black;
  text-decoration: none;

  &:link {
    color: inherit;
  }

  &:visited {
    color: black;
  }
}

/////////////////////////////////////////////////////////////////////////////////
///
// Typography
//
/////////////////////////////////////////////////////////////////////////////////

h1 {
  font-size: 32px;
  font-weight: bold;
}

h2 {
  font-size: 24px;
  font-weight: bold;
}

h3 {
  font-size: 18px;
  font-weight: bold;
}

/////////////////////////////////////////////////////////////////////////////////
///
// Hacks
//
/////////////////////////////////////////////////////////////////////////////////

//time picker hack
.timepicker-overlay {
  z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}

//checkbox mobile touch area
.mat-mdc-checkbox-touch-target {
  height: 48px;
  width: 48px;
}

//hide component header in dialog pages
.cdialog {
  mat-toolbar {
    display: none !important;
  }
}

///override dialog header (to slove the title overflow issue)
.dialogHeader {
  display: grid !important;
  grid-template-columns: auto min-content min-content;
  align-items: flex-start !important;
}
.mdc-dialog__title {
  max-width: none !important;
}
.actionButton {
  margin-top: 10px !important;
}
.closeButton {
  margin-top: 10px !important;
}
///////

.mdc-dialog__title::before {
  display: none !important;
}

.mat-mdc-menu-content {
  padding: 0 !important;
  > div {
    padding: 10px;
    cursor: pointer;

    &:hover {
      background: #eee;
    }
  }
}

.mat-elevation-z8 {
  box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.1) !important;
}
.ck-editor__editable {
  .ck-list-bogus-paragraph {
    display: unset !important;
  }
}


//responsive dialog
.cdialog {
  //mobile
  @media screen and (max-width: $mobile-width) {
    width: 100% !important;
    max-width: none !important;
    height: auto !important;
    max-height: 95vh !important;
  }
}

//drop down list
.cdk-overlay-pane {
  width: fit-content !important;
  min-width: 150px;
}

///////////////////////////
//override
///////////////////////////
/// 
.app-btn {
  background-color: #2E2E2E;
}

.confirmDialog {
  .closeButton {
    color: $client-text-color;
  }
  .confirmDialog_cancel{
    color: $client-text-color;

    &:hover {
      color: $client-text-color; 
    }
  }
  .confirmDialog_confirm {
    background: $client-theme-color;
  }
}

.imageContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.mat-datepicker-toggle {
  padding-right: 8px;

  .mat-mdc-icon-button svg {
    color: #2e2e2e;
  }
}


.ctlSidebarLayout {
  //mobile
  @media screen and (max-width: $mobile-width) {
    flex-direction: column-reverse !important;
    padding: 0px;

    .sidebarBody {
      flex: 1 1 auto;
      width: 100%;
    }

    .sidebarRight {
      margin-left: 0px;
    }
  }
}

.calendar_SidebarLayout {
  //mobile
  @media screen and (max-width: $mobile-width) {
    //flex-direction: column-reverse !important;
    //padding: 0px;

    .calendar_sidebar {
      flex: 1 1 auto !important;
    }
  }
}

/////////////////////////////////////////////////////////////////////////////////
///
// Navigation
//
/////////////////////////////////////////////////////////////////////////////////

$border-color: #e4e4e4;
$light-text: #999999;
$border-color-inactive: #dddddd;

//Mobile Bottom Menu
.client_nav_mobile {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background: black;
  width: 100vw;

  .nav_item {
    height: 60px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div:first-child {
      width: 30px;
      display: flex;
      justify-content: center;
      font-size: 20px;
    }
  }

  @media screen and (min-width: $mobile-width) {
    display: none;
  }
}

.client_nav_mobile_side {
  position: absolute;
  background-color: white;
  border-right: 1px solid #ccc;
  top: 0;
  bottom: 0;
  left: 0;
  right: 10vw;
  z-index: 10000;

  .side_nav_container {
    padding: 1rem;
    overflow: scroll;

    .nav_subtitle {
      font-size: small;
      color: #707070;
      margin-bottom: 10px;
    }

    .nav-item {
      margin-bottom: 10px;
    }
  }
}

/////////////////////////////////////////////////////////////////////////////////
///
// COmponent
//
/////////////////////////////////////////////////////////////////////////////////
.ctlMonthSelector {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: inline-block;
  }

  .button {
    padding: 10px 15px;

    &:hover {
      background-color: #eee;
    }
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    margin: 10px;
    cursor: pointer;
  }
}

.ctlCalendar {
  box-shadow: 1px 1px 15px -5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background: white;

  .grid {
    display: grid;
    padding: 0px;
    flex: 1 1 auto;
    overflow: auto;

    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: fit-content(30px);
    //grid-auto-rows: 1fr;
    height: 100%;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    box-sizing: border-box;

    .calendarHeader {
      border-left: 1px solid #e4e4e4;
      padding: 2px;
      font-weight: bold;

      &.firstHeader {
        border-left: none;
      }
    }

    .date {
      border-top: 1px solid #e4e4e4;
      border-left: 1px solid #e4e4e4;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      min-height: 90px;

      &.firstDate {
        border-left: none;
      }

      .item {
        font-size: 0.9em;
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .dateItems {
        overflow: auto;
        flex: 1 1 auto;
        padding: 1px;
      }

      .cross {
        text-decoration: line-through;
        color: #888;
      }

      .more {
        width: 100%;
        background-color: #eee;
        border: 1px solid #ccc;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }

  // .date {
  //   height: 120px;
  // }

  .otherMonth {
    color: $light-text;
  }

  .item {
    background-color: #eee;
    //border: 1px solid $border-color;
    padding-left: 5px;
    border-radius: 3px;
  }
}

.ctlDayCalendar {
  .headerTable {
    margin-right: 5px;
  }
  .table {
    display: grid;
    grid-auto-rows: 50px;
    grid-template-columns: 75px repeat(7, 1fr) !important;

    background-color: #ccc;
    border: 1px solid #ccc;
    grid-gap: 1px;

    > div {
      background-color: white;
      position: relative;
      min-width: 130px;
    }

    .header {
      padding: 5px;
      min-width: 130px;

      .date {
        font-weight: bold;
      }
    }

    .colHeader {
      padding: 0 5px;
    }

    .rowHeader {
      position: sticky;
      z-index: 500;
      width: 75px;
      min-width: 75px !important;
      padding: 5px;

      font-size: smaller;
    }

    .item {
      width: calc(100% - 10px);
      position: absolute;
      margin-left: 5px;
      box-sizing: border-box;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background-color: rgb(255, 220, 40);
      border: 1px solid #ccc;
      z-index: 500;

      cursor: pointer;
    }
  }
}

.imageUpload {
  .fileUpload {
    //border: 2px dashed #ccc;
    //min-height: 120px;
    //width: 98%;
  }

  .fileOver {
    background-color: 0xeee;
  }

  .imageTrash {
    position: absolute;
    z-index: 50;
    color: white;
    right: 0px;
    margin: 5px;
    cursor: pointer;
    background: rgba(112, 112, 112, 0.6);
    padding: 5px;
    border-radius: 18px;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    opacity: 0;
  }

  .imageContainer {
    //padding: 5px;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
  }

  .imagePreviewContainer {
    height: 100px;
    width: 100px;
    margin: 5px;
    position: relative;

    &:hover {
      .imageTrash {
        opacity: 1;
      }
    }
  }

  .imagePreview {
    width: 100px;
    height: 100px;
    max-width: fit-content;
    border: 1px solid #eee;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    object-fit: cover;

    &:hover {
      .imageTrash {
        opacity: 1;
      }
    }
  }

  .imageSelected {
    opacity: 0.4;
    filter: alpha(opacity=40);
  }

  .add {
    display: flex;
    border: 1px solid #e4e4e4;
    padding: 5px 15px 5px 10px;
    border-radius: 3px;
    align-items: center;

    .buttons {
      margin-left: 20px;
      margin-top: 3px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 20px;

      &:hover {
        color: var(--viz-blue);
        cursor: pointer;
      }
    }
  }

  .addImageButton {
    min-width: 100px;
    height: 100px;
    margin: 5px;
    position: relative;

    line-height: 100px;
    text-align: center;
    background: #e4e4e4;
    //margin-left: 8px;
    color: white;
    font-size: 20px;
    cursor: pointer;
    border-radius: 3px;

    .subImageButton {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 90px;
    }
  }

  &.imageUploadVertical {
    .imageContainer {
      padding: 5px;
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }

    .imagePreviewContainer {
      height: 100px;
      width: calc(100% - 10px);
    }

    .imagePreview {
      width: 100%;
      height: 100px;
    }
  }

  &.imageUploadBanner {
    //max-width: 600px;
    .imageContainer {
      padding: 5px;
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }

    .imagePreviewContainer {
      // height: 90px;
      position: relative;
      //padding-bottom: 10.868%;
      padding-bottom: 25%;
      overflow: hidden;
      width: calc(100% - 10px);
      height: 0px;
    }

    .imagePreview {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
    }
  }

  &.imageContains {
    .imagePreview {
      object-fit: contain;
    }
  }
}

//override app-form-field
.app-form-field {
  .app-form-field-container {
    border: 1px solid black;
    border-radius: 20px;

    .app-form-field-content input {
      padding: 8px 15px;
    }

    .app-form-field-content mat-select {
      padding: 8px 15px;
    }
  }
}

//override map component
.map-container {
  height: 90vh !important;
}

//override calendar component
.mat-calendar-header {
  padding: 0px !important;

  .mdc-button__label {
    font-weight: bold;
  }
}
.mat-calendar-controls {
  margin: 0px !important;
}

.hourSelect_dataLabel {
  display: flex;
  gap: 10px;
  justify-content: center;
  font-size: smaller;

  .dataLabel {
    display: flex;
    align-items: center;
    gap: 5px;

    .labelColor {
      width: 15px;
      height: 15px;
      border-radius: 5px;
      //default
      border: 1px solid #ccc;
      background: white;
  
      &.unavailable {
        background: #ccc;
      }
  
      &.selected {
        border: 1px solid black;
        background: black;
      }
    }
  }
  
}

.hourChip {

  &:hover {
    border: 1px solid black !important;
  }

  &.selected {
    background-color: black !important;
    color: white;
  }

  &.disabled {
    border: 1px solid #ccc !important;
  }
}

////////////////////////////////
///Client Component
////////////////////////////////
.stepper {
  display: flex;
  width: 100%;
  max-width: 800px;
  justify-content: center;
  align-items: center;

  .stepper_item {
    height: 35px;
    width: 35px;
    border: 2px solid #ccc;
    border-radius: 50%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;

    &.current {
      border: 2px solid black;
      font-weight: bold;
    }
  }
  .progressbar {
    height: 2px;
    background: #ccc;
    flex: 1 1 auto;

    &.completed {
      background: black;
    }
  }
}

.client_button {
  height: 50px;
  width: fit-content;
  min-width: 150px;
  color: white;
  background: black;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 50px;
  cursor: pointer;

  &.btn_outline {
    color: black;
    background: white;
  }

  &.btn_circle {
    color: black;
    background: white;
    border: 1px solid #ccc;
    border-radius: 50%;
    height: 42px;
    width: 42px;
    padding: 0;
    min-width: 42px;
  }

  &.float_btn {
    border-radius: 50%;
  }

  &.disabled {
    color: #707070;
    background: #ccc;
    border: none;
  }
}

.client_chip {
  font-size: 0.625rem;
  padding: 0rem 0.5rem;
  height: 1.25rem;
  line-height: 1.25rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: #707070;
  background: #eee;
  width: fit-content;

  &.free {
    color: #409E4C;
  }
  &.fee {
    color: #1CA0DA;
  }
  &.custom {
    color: #1CA0DA; 
  }
}

.client_itemSelect {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  background: white;
  //width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.selected {
    border: 2px solid #409E4C;
  }

  .selected_icon {
    color: #409E4C;
    position: absolute;
    top: -9px;
    right: -4px;
    font-size: 1.2rem;


  }
}

.client_tab {
  height: 30px;
  color: black;
  background: white;
  border: 1px solid black;
  border-radius: 20px;
  width: fit-content;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.selected {
    color: white;
    background: black;
  }
}

.search_bar {
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid #ccc;
  padding: 5px 20px;
  border-radius: 20px;
  width: 100%;
  max-width: 600px;
  height: 42px;

  .searchIcon {
    margin-right: 10px;
  }
}
.search_bar input:focus-visible {
  outline: none;
}

.client_header_desktop {
  display: flex;
  gap: 2rem;
  background: white;
  padding: 10px;
  border-bottom: 1px solid #eee;
  align-items: center;

  .nav-item {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  @media screen and (max-width: $mobile-width) {
    display: none;
  }
}
.client_header_mobile {
  height: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  background: white;
  padding: 10px 10px 0 10px;
  border-bottom: 1px solid #eee;

  @media screen and (min-width: $mobile-width) {
    display: none;
  }
}

.client_pageBody {
  display: flex;
  flex-direction: column;
  // flex: 1 1 auto;
  // height: 100%;

  width: 1200px;
  margin: 1rem auto;

  background: #f9f9f9;

  .page_Title {
    font-size: 2rem;
    font-weight: bold;
    padding: 0 1rem;
  }

  &.home_body_top {
    // height: fit-content;
    margin: auto;
    width: auto;
    // height: auto;
    background: white;
  }

  &.home_body_bottom {
    margin: auto;
    // width: auto;
    // height: auto;
    background: #f9f9f9;
  }

  &.body {
    margin: auto;
    width: auto;
    height: auto;
    background: white !important;
  }

  .content {
    width: 1200px;
    margin: auto;

    @media screen and (max-width: $mobile-width) {
      width: 100% !important;
    }
  }

  @media screen and (max-width: $mobile-width) {
    width: 100% !important;
  }
}

.passcode_box {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-size: large;
  font-weight: bold;
  margin-top: 10px;
}

.terms_page {
  ul {
    padding-left: 20px;
    list-style: disc;
}
}

.space_book_page {
  //mobile
  @media screen and (max-width: $mobile-width) {
    padding: 1rem;
    margin: 0px;
  }
}

.body_section {
  padding: 1rem;
  .sectionHeader {
    display: flex;
    align-items: baseline;
    margin-bottom: 1rem;
    .title {
      font-size: 1.5rem;
      font-weight: bold;
    }
    .view_all_btn {
      display: flex;
      gap: 5px;
      cursor: pointer;
      align-items: center;

      .arrow_icon {
        font-size: large;
        margin-top: 2px;
      }
    }
  }
}

.item_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  overflow: auto;

  @media screen and (max-width: $mobile-width) {
    display: flex;
    flex-direction: column;
  }
}

.location_card {
  background: white;
  border-radius: 5px;
  border: 1px solid #eee;
  display: grid;
  grid-template-columns: 155px 1fr;
  cursor: pointer;

  margin-bottom: 10px;
  //box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.08);

  @media screen and (max-width: $mobile-width) {
    grid-template-columns: auto;
  }

  .img_container {
    @media screen and (max-width: $mobile-width) {
      position: relative;
      aspect-ratio: 3/2;
    }

  }

  img {
    width: 100%;
    //height: 125px;
    height: 155px;
    border-radius: 5px 0 0 5px;
    object-fit: cover;

    @media screen and (max-width: $mobile-width) {
      border-radius: 5px 5px 0 0;
      //position: absolute;
    }
  }

  .space_tag {
    background: white;
    bottom: 0;
    right: 0;
    padding: 2px 5px;
    margin: 5px;
    border-radius: 3px;
    font-size: 12px;
    color: #409E4C;

  }

  .info {
    padding: 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}

.location_card_large {
  .location_card {
    grid-template-columns: none;
    grid-template-rows: 155px 130px;
    
    img {
      border-radius: 5px 5px 0 0;
    }
  }
}

.space_card {
  background: white;
  border-radius: 5px;
  border: 1px solid #eee;
  display: grid;
  grid-template-columns: 155px 1fr;
  cursor: pointer;

  //margin-bottom: 10px;
  //box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.08);

  img {
    width: 100%;
    height: 125px;
    border-radius: 5px 0 0 5px;
    object-fit: cover;
  }

  .space_info {
    padding: 0.7rem 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .space_capacity {
    flex-wrap: wrap;
  }
}
//override space_card
.space_card_noBorder {
  .space_card {
    border: none;
    box-shadow: none;
  }
  img {
    border-radius: 5px;
  }
}
.space_card_small {
  .space_card {
    border: none;
    box-shadow: none;
    display: block;
    width: 200px;

    .space_info {
      padding: 0;
      padding-top: 10px;
    }
  }
  img {
    border-radius: 5px;
    width: 200px;
  }
}
.space_card_booking {
  .space_capacity {
    display: none;
  }
  .spaceType-tags {
    display: none;
  }
}

.spaceType-tags:not(:last-child):after {
  content: "•";
  //margin: 0 5px;
}

.list_comma_separated:not(:last-child):after {
  content: ",";
  margin-right: 3px;
}

.item_details_page {
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 1.25rem;
  padding-top: 1rem;

  //mobile
  @media screen and (max-width: $mobile-width) {
    grid-template-columns: 100%;
    padding-top: 0px !important;
    margin: 0px !important;

    .tt_title {
      margin-top: 1rem;
    }
  }

  .tt_sidebar {
    display: flex;
    flex-direction: column;
    width: 400px;

    //mobile
    @media screen and (max-width: $mobile-width) {
      width: 100%;
    }

    .tt_sidebar_image {
      // width: 400px;
      // height: 400px;
      height: auto;
      aspect-ratio: 3/2;
      object-fit: cover;
      cursor: pointer;
      border-radius: 5px;
      margin-bottom: 10px;

      //mobile
      @media screen and (max-width: $mobile-width) {
        width: 100%;
        height: 100%;
        border-radius: 0px;
      }
    }

    .tt_sidebar_image_container {
      width: 400px;
      background: white;
      border-radius: 5px;

      //mobile
      @media screen and (max-width: $mobile-width) {
        width: 100%;
      }

      .tt_sidebar_image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }

    .tt_sidebar_carousel {
      position: relative;
      height: 100px;
      margin-top: 0.2rem;
    }

    .tt_sidebar_subimage {
      width: 100px;
      height: 100px;
      margin-right: 5px;
      object-fit: cover;
      object-fit: cover;
      cursor: pointer;
      background: white;
      border-radius: 5px;
    }
  }

  .tt_right {
    //mobile
    @media screen and (max-width: $mobile-width) {
      margin: 1rem;
    }
  }

  .tt_button {
    margin-top: 1rem;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      max-width: 520px;
    }
  }



  .item_info {
    margin: 20px 3px;
  }
}
.item_info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #707070;
  .line_item {
    display: flex;
    align-items: flex-start;

    >div {
      width: 100%;
    }

    .icon {
      width: 30px;
      display: flex;
      gap: 10px;
      justify-content: center;
      color: #000;
      align-items: center;

      svg {
        width: 16px;
        height: 20px;
      }
    }

  }
}

.client_login_page {
  flex: 1 1 auto;
  height: calc(100vh - 63px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(/background.svg);
  background-size: cover;
  padding: 1rem;

  //mobile
  @media screen and (max-width: $mobile-width) {
    justify-content: start;
  }

  .login_card {
    width: fit-content;

    //mobile
    @media screen and (max-width: $mobile-width) {
      width: 100%;
    }


    
  }
}

.client_profile_page {
  max-width: 350px;
  margin: auto;
  filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.08));

  .profile_card_top {
    margin-top: 10%;
    background: white;
    height: 250px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //box-shadow: 5px -5px 10px 0px rgba(0,0,0,0.1);
  }

  .profile_card {
    background: white;
    //box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.1);
    margin: auto;
    text-align: center;

  }

  .profile_card_bottom {
    background: white;
    min-height: 250px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    //box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.1)
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

}

.full-dialog {
  @media screen and (max-width: $mobile-width) {
    width: 95vw !important;
    max-width: 95vw !important;
    max-height: 80vh !important;
  }

}

.overlay_dialog {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .overlay_dialog_content {
    margin: auto;
    background: white;
    width: fit-content;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    max-height: 90vh;
    overflow: scroll;

    //mobile
    @media screen and (max-width: $mobile-width) {
      max-height: 80vh;
    }
  }
}

.inputField_placeholder {
  display: flex;
  border: 1px solid black;
  border-radius: 20px;
  min-height: 40px;
  background: white;
  padding: 8px 15px;
  cursor: pointer;
  align-items: center;
  gap: 10px;
}

.map_widget {

  .gm-style-iw {
    min-width: 180px !important;
    max-width: 350px !important;
  }
  .gm-style-iw-chr {
    position: absolute;
    right: 0;
  }
  .gm-style-iw-d {
    margin: 1rem 1rem 0 0;
  }

  .info_item {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 10px;
    font-family: "Noto Sans TC, inter-var, Helvetica Neue, sans-serif, Microsoft JhengHei";
  }

  *:focus {
    outline: none;
  }
}

.lang-chip {
  background: #eee;
  width: fit-content;
  padding: 5px 15px;
  border-radius: 5px;
  font-weight: bold;
}

.Status {
  &.pending {
    color: white;
    background: #F6C50F;
  }

  &.rejected {
    color: white;
    background: #707070;
  }

  &.approved {
    color: white;
    background: #409e4c;
  }

  &.cancelled {
    color: white;
    background: #707070;
  }
}

.ckeditor-content {
  list-style: auto !important;
  word-break: break-word;

  ol {
    padding: 1rem;
    list-style: auto;
  }

  ul {
    padding: 1rem;
    list-style: disc;
  }
}



.myBooking_remind {
  display: flex;
  margin-right: 10%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: larger;
}

.itemImages {
  .image_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;

    img {
      cursor: pointer;
      border-radius: 5px;
      transition: 0.3s;

      &:hover {
        opacity: 0.8;
      }

      &.selected {
        border: 1px solid $client-theme-color;
      }
    }
  }
  
  .image_carousel {
    height: 200px;
    border: 1px solid #ccc;
    position: relative;

    img {
      width: 100%;
    }
  
  }
}

.facilityList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;

  //mobile
  @media screen and (max-width: $mobile-width) {
    grid-template-columns: repeat(2, 1fr);
  }

  .facility_item {
    display: flex;
    gap: 5px;
    border: 1px solid #eee;
    border-radius: 3px;
    background: white;
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.08);
    padding: 5px;

    .info {
      padding: 5px;
    }

  }

}








