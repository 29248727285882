$mobile-width: 62rem;

:root {
  --viz-blue: #1ca0da;
  --viz-light-blue: #e7f5fb;
  --viz-light-blue2: #a6e0f8;

  --viz-green: #408e4c;
  --viz-light-green: #c5e2c9;
  --viz-light-green2: #b6dabb;

  --viz-yellow: #f6c50f;
  --viz-light-yellow: #fceeb7;
  --viz-light-yellow2: #fce9a4;

  --viz-pink: #ec3475;
  --viz-light-pink: #f9c2d5;
  --viz-light-pink2: #f9c2d5;

  --viz-brown: #744a25;
  --viz-light-brown: #d5c8bd;
  --viz-light-brown2: #cbbbad;

  --viz-grey: #707070;
  --viz-light-grey: #eee;
  --viz-light-grey2: #ccc;

  --viz-red: #ff4141;
  --viz-light-red: #ffbcbc;
  --viz-light-red2: #ff8d8d;

  --viz-warn: #f16c5d;

  --viz-main: #1ca0da;
  // pending refunded
  // #f6c50f;

  // refer to custom_label
  // &.orange {
  //   background: #fcedb7;
  //   color: #fe9a02;
  // }

  // &.blue {
  //   background: #bae2f4;
  //   color: #1ca0da;
  // }

  // &.green {
  //   background: #409e4c;
  //   color: white;
  // }

  //red
  // ff5a5a
  //ffa500

  // brown
  // 744a25
  --facebook-blue: #0866ff;
}

.soft-blue {
  color: var(--viz-blue);
  background-color: var(--viz-light-blue);
}

.text-blue {
  color: var(--viz-blue);
}

.text-green {
  color: var(--viz-green);
}

.text-red {
  color: var(--viz-red);
}

.text-warn {
  color: var(--viz-warn);
  font-size: 0.8rem;
}

///////////////////////////////////////////////////////////////
///
// text style system
//
///////////////////////////////////////////////////////////////

.text-exp {
  color: #999;
  font-size: 0.8rem;
  font-weight: normal;
  line-height: normal;
}

.text-header {
  font-size: 1.25rem;
  font-weight: bold;
  color: #1a1818;
}

//inside cardbody
.text-title {
  font-weight: bold;
  font-size: 1rem;
}

//Instructions
.pageInstruction {
  font-size: 12px;
  line-height: 10px;
  font-weight: 300;
  color: var(--viz-blue);
  padding: 10px;
  background: rgba(231, 245, 251, 0.7);
}

.instructionBox {
  background: #f0f0f0;
  //color: #707070;
  padding: 10px;
  //margin: 20px;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.fieldInstruction {
  font-size: 12px;
  font-weight: 300;
  color: #ccc;
  margin-top: -10px;
}

///////////////////////////////////////////////////////////////
///
// common width
//
///////////////////////////////////////////////////////////////

.width-50 {
  width: 60px;
  min-width: 60px;
  flex: 0 0 60px;
}

.width-60 {
  width: 60px;
  min-width: 60px;
  flex: 0 0 60px;
}

.width-80 {
  width: 80px;
  min-width: 80px;
  flex: 0 0 80px;
}

.width-100 {
  width: 100px;
  min-width: 100px;
  flex: 0 0 100px;
}

.width-120 {
  width: 120px;
  flex: 0 0 120px;
}

.width-150 {
  width: 150px;
  flex: 0 0 150px;
}

.width-200 {
  width: 200px;
  flex: 0 0 200px;
}

.width-300 {
  width: 300px;
  flex: 0 0 300px;
}

////////////////////////////////////////////////
///
/// Vizualize UI Components
////////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////
.app-btn {
  $btn-disabled-color: #bbb;
  $btn-disabled-bg: #eee;
  padding: 0rem 0.5rem;
  font-weight: 500;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.2rem;
  min-width: max-content;
  cursor: pointer;
  @apply shadow;

  &[disabled] {
    color: $btn-disabled-color;
    background-color: $btn-disabled-bg;
  }

  // default color
  background-color: var(--viz-main);
  color: white;

  //default size
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.5rem 0.75rem;
  height: 2.25rem;

  // type
  &.btn-soft {
    color: var(--viz-blue);
    background-color: var(--viz-light-blue);

    &:hover {
      background: var(--viz-light-blue2);
    }

    &[disabled] {
      color: $btn-disabled-color;
      background-color: $btn-disabled-bg;
    }
  }

  &.btn-outline {
    border: 1px solid var(--viz-light-grey2);
    background-color: white;
    color: black;

    &[disabled] {
      color: $btn-disabled-color;
      background-color: $btn-disabled-bg;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    box-shadow: none;
  }

  &.btn-text {
    background-color: transparent;
    color: black;
    box-shadow: none;

    &:hover {
      background-color: var(--viz-light-grey);
      color: var(--viz-blue);
    }

    &[disabled] {
      color: $btn-disabled-color;

      &:hover {
        background-color: transparent;
      }
    }
  }

  /////// color
  &.btn-black {
    background-color: #000;
    color: white;

    &.btn-soft {
      background-color: var(--viz-light-grey);
      color: black;

      &:hover {
        background-color: var(--viz-light-grey2);
      }
    }

    &.btn-outline {
      color: black;
      border-color: black;
      background-color: white;

      &:hover {
        background: var(--viz-light-grey);
      }
    }

    &.btn-text {
      background-color: transparent;
      color: black;

      &:hover {
        background-color: var(--viz-light-grey);
      }
    }

    &[disabled] {
      color: $btn-disabled-color;
      background-color: $btn-disabled-bg;
    }
  }

  &.btn-blue {
    background-color: var(--viz-blue);
    color: white;

    &.btn-soft {
      color: var(--viz-blue);
      background-color: var(--viz-light-blue);

      &:hover {
        background: var(--viz-light-blue2);
      }
    }

    &.btn-outline {
      color: var(--viz-blue);
      border-color: var(--viz-blue);
      background-color: white;

      &:hover {
        background: var(--viz-light-blue);
      }
    }

    &.btn-text {
      background-color: transparent;
      color: var(--viz-blue);

      &:hover {
        background-color: var(--viz-light-grey);
      }
    }
  }

  &.btn-green {
    color: white;
    background: var(--viz-green);

    &:hover {
    }

    &.btn-soft {
      color: var(--viz-green);
      background-color: var(--viz-light-green);

      &:hover {
        background: var(--viz-light-green2);
      }
    }

    &.btn-outline {
      color: var(--viz-green);
      border-color: var(--viz-green);
      background-color: white;

      &:hover {
        background: var(--viz-light-green);
      }
    }

    &.btn-text {
      background-color: transparent;
      color: var(--viz-green);

      &:hover {
        background-color: var(--viz-light-grey);
      }
    }

    &[disabled] {
      color: $btn-disabled-color;
      background-color: $btn-disabled-bg;
    }
  }

  &.btn-red {
    color: white;
    background: var(--viz-red);

    &:hover {
    }

    &.btn-soft {
      color: var(--viz-red);
      background-color: var(--viz-light-red);

      &:hover {
        background: var(--viz-light-red2);
      }
    }

    &.btn-outline {
      color: var(--viz-red);
      border-color: var(--viz-red);
      background-color: white;

      &:hover {
        background: var(--viz-light-red);
      }
    }

    &.btn-text {
      background-color: transparent;
      color: var(--viz-red);

      &:hover {
        background-color: var(--viz-light-red);
      }
    }

    &[disabled] {
      color: $btn-disabled-color;
      background-color: $btn-disabled-bg;
    }
  }

  // size
  &.btn-xs {
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.25rem 0.5rem;
    height: 1.5rem;
    border-radius: 0.25rem;
  }

  &.btn-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.25rem 0.5rem;
    height: 1.75rem;
    border-radius: 0.25rem;
  }

  &.btn-md {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.375rem 0.625rem;
    height: 2rem;
  }

  &.btn-lg {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.5rem 0.75rem;
    height: 2.25rem;
  }

  &.btn-xl {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.625rem 0.875rem;
    height: 2.5rem;
  }

  &.btn-circle {
    width: 2rem;
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.btn-sm {
      width: 1.75rem;
    }

    &.btn-md {
      width: 2rem;
    }

    &.btn-lg {
      width: 2.25rem;
    }
  }

  &.btn-square {
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;

    &.btn-sm {
      width: 1.75rem;
      height: 1.75rem;
    }

    &.btn-md {
      width: 2rem;
      height: 2rem;
    }

    &.btn-lg {
      width: 2.25rem;
      height: 2.25rem;
    }
  }

  &.btn-round {
    border: 200px;
  }

  &.btn-icon {
    width: 2rem;
    height: 2rem;
    line-height: 2em;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: black;
    box-shadow: none;

    &:hover {
      background-color: var(--viz-light-grey);
    }

    &[disabled] {
      color: #bbb;
    }

    &.btn-sm {
      font-size: 0.75rem;
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
    }

    &.btn-md {
      font-size: 1rem;
      width: 2rem;
      height: 2rem;
      line-height: 2em;
    }

    &.btn-lg {
      font-size: 1.5rem;
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
    }
  }
}

.text-link {
  cursor: pointer;
  font-size: 0.9rem;

  &:hover {
    text-decoration: underline;
  }

  &.link-blue {
    color: var(--viz-blue);
  }

  &.link-grey {
    color: var(--viz-grey);

    &:hover {
      color: var(--viz-blue);
      text-decoration: underline;
    }
  }
}

.radio-btn {
  display: flex;
  background: white;
  margin-top: 12px;
  padding: 10px 12px;
  border-radius: 5px;
  border: 1px solid #eee;
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover {
    background: #eee;
    border: 1px solid #1a1818;
  }

  &.selected {
    background: #eee;
    border: 1px solid #1a1818;
  }
}

//eg booking record option
.app-badge {
  font-weight: normal;
  border-radius: 5px;
  display: flex;

  // default color
  background: var(--viz-light-grey2);
  color: black;

  // default size
  font-size: 0.75rem;
  padding: 0rem 0.5rem;
  height: 1.5rem;
  line-height: 1.5rem;

  // size
  &.badge-sm {
    font-size: 0.625rem;
    padding: 0rem 0.5rem;
    height: 1.25rem;
    line-height: 1.25rem;
  }

  &.badge-md {
    font-size: 0.75rem;
    padding: 0rem 0.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }

  &.badge-lg {
    font-size: 0.875rem;
    padding: 0rem 0.75rem;
    height: 2.125rem;
    line-height: 2.125rem;
    border-radius: 1rem;
  }

  //colors
  &.badge-blue {
    color: var(--viz-blue);
    background-color: var(--viz-light-blue);
  }

  &.badge-green {
    color: var(--viz-green);
    background-color: var(--viz-light-green);
  }
}

.app-selector {
  border-radius: 5px;
  display: flex;
  align-items: center;
  max-width: 15vw;
  min-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  cursor: pointer;

  // default color
  background: white;
  color: #707070;
  border: 1px solid #ccc;

  // default size
  font-size: 0.875rem;
  padding: 0rem 0.75rem;
  height: 2rem;
  line-height: 2rem;

  // size
  &.selector-sm {
    font-size: 0.625rem;
    padding: 0rem 0.5rem;
    height: 1.25rem;
    line-height: 1.25rem;
  }

  &.selector-md {
    font-size: 0.75rem;
    padding: 0rem 0.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }

  &.selector-lg {
    font-size: 0.875rem;
    padding: 0rem 0.75rem;
    height: 2rem;
    line-height: 2rem;
  }

  //selected style

  &.selected {
    color: black;
    border: 1px solid black;
  }

  &.selector-blue {
    &.selected {
      color: var(--viz-blue);
      border: 1px solid var(--viz-blue);
    }
  }
}
.app-paginator {
  display: flex;
  align-items: center;
  font-size: small;
  margin: 5px 10px;
  margin-top: 0px;

  .paginator-button {
    margin: 0 5px;
    padding: 10px;
    cursor: pointer;
  }
  .paginator-page {
    padding: 5px 10px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: white;
    }
  }
  .paginator-current-page {
    background-color: black;
    color: white;

    &:hover {
      background-color: black;
    }
  }
  .paginator-text {
  }
}
.app-ui {
  font-size: 0.875rem;
}

///////////////////////////////////////////////////////////////
///
// Form fields style
//
///////////////////////////////////////////////////////////////

app-form-field {
  display: inline-block;
  width: max-content;
  min-width: 100px;
  //margin-bottom: 0.5rem;

  input:disabled {
    color: #666;
  }
}

.dateField {
  width: 150px;
}
.dateOnlyField {
  width: 80px;
  min-width: none;
}

.timeField {
  width: 90px;
  min-width: none;
}

.priceField {
  width: 150px;

  input {
    text-align: right;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.weightField {
  width: 7em;
  min-width: 7em;

  input {
    text-align: right;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.numField {
  input {
    text-align: right;
  }
}

.app-form-label {
  @extend .app-ui;
  padding: 2px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.app-bare-form-field {
  @extend .app-ui;
  .app-label {
    padding: 2px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .app-field {
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    align-items: stretch;
    background: white;
    padding: 8px;
  }
}

.app-form-field {
  width: 100%;
  //margin-bottom: 0.25rem;

  .app-label {
    //font-size: 0.9rem;
    //height: 1.2rem;
    padding: 2px;
    display: flex;
    align-items: center;
    gap: 5px;

    app-label {
      //flex: 1 1 auto;
      //font-weight: bold;
    }

    app-hint {
      color: #ccc;
      font-size: 0.8rem;
    }
  }

  .app-form-field-line {
    display: flex;
    align-items: center;
  }

  .app-form-field-container {
    flex: 1 1 auto;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    align-items: stretch;
    background: white;

    &.app-form-field-container-selected {
      //border-width: 2px;
      border-color: black;
    }

    .app-field-icon-prefix {
      padding: 0px 8px;
      display: flex;
      align-items: center;
      text-wrap: nowrap;
      white-space: nowrap;
    }

    .app-field-icon-suffix {
      padding: 0px 8px;
      display: flex;
      align-items: center;
      text-wrap: nowrap;
      white-space: nowrap;

      .mat-datepicker-toggle {
        margin-right: -8px;

        .mat-mdc-icon-button {
          padding: 0px;
          height: 36px;
          width: 36px;
        }

        .mat-mdc-button-touch-target {
          height: 24px;
          width: 24px;
        }
      }
    }

    .app-form-field-content {
      flex: 1 1 auto;
      display: flex;
      align-items: center;

      input {
        height: 2.75em;
      }

      input,
      textarea {
        background-color: transparent;
        padding: 8px;
        width: 100%;
        border: none;
        outline: none;
      }

      textarea {
        height: 80px;
      }

      input:focus,
      input:focus-visible,
      textarea:focus,
      textarea:focus-visible {
        outline: none;
        background-color: transparent;
        border: none;
      }

      .mat-date-range-input-container {
        padding-left: 8px;

        .mat-date-range-input-wrapper {
          input {
            padding: 0px;
            height: auto;
          }
        }
      }

      mat-select {
        padding: 8px;
        // min-width:200px;
      }
    }
  }

  .app-form-field-help {
    color: #999;
    padding: 0px 4px;
    font-size: 0.8rem;
  }

  .app-form-field-error {
    padding: 0px 4px;
    color: red;
    font-size: 0.8rem;
  }

  //state
  &.field-invalid {
    .app-form-field-container {
      //border-width: 2px;
      border-color: red;
    }
  }

  &.field-disabled {
    .app-form-field-container {
      background-color: #f8f8f8;
    }
  }

  // modifier
  &.prefix-border {
    .app-form-field-container {
      .app-field-icon-prefix {
        border-right: 1px solid #ccc;
      }
    }
  }

  &.suffix-border {
    .app-form-field-container {
      .app-field-icon-suffix {
        border-left: 1px solid #ccc;
      }
    }
  }
}

//drag and drop list
.droplist {
  width: 500px;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 5px;
  overflow: hidden;

  .droplist-item {
    padding: 20px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    border-bottom: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);

    padding: 10px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 5px;

    .cdk-drop-list-dragging {
      cursor: grabbing;

      .example-box:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }

  &:last-child {
    border: none;
  }

  &:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

// color selection
.app-color-picker,
.colorPickDiv {
  width: 22px;
  height: 22px;
  border: 1px solid #666;
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 3px;
}

ngx-colors {
  .app-color-picker {
    width: auto;
    height: auto;
    border: none;
    margin:auto;
  }
}
.app-chip {
  border-radius: 0.625rem;
  width: fit-content;
  min-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;

  // default color
  background: var(--viz-light-grey2);
  color: white;

  // default size
  font-size: 0.75rem;
  padding: 0rem 0.5rem;
  height: 1.5rem;
  line-height: 1.5rem;

  // type
  &.chip-text {
    border: solid 1px #f0f0f0;
    background: transparent;
    color: var(--viz-grey);
    font-weight: 500;
  }

  // color
  &.chip-blue {
    background-color: var(--viz-blue);
    color: white;

    &.chip-text {
      background: transparent;
      color: var(--viz-blue);
    }
  }

  &.chip-green {
    background-color: var(--viz-green);
    color: white;

    &.chip-text {
      background: transparent;
      color: var(--viz-green);
    }
  }

  &.chip-yellow {
    background-color: var(--viz-yellow);
    color: white;

    &.chip-text {
      background: transparent;
      color: var(--viz-yellow);
    }
  }

  &.chip-grey {
    background-color: var(--viz-grey);
    color: white;

    &.chip-text {
      background: transparent;
      color: var(--viz-grey);
    }
  }

  // size
  &.chip-sm {
    font-size: 0.625rem;
    padding: 0rem 0.5rem;
    height: 1.25rem;
    line-height: 1.25rem;
  }

  &.chip-md {
    font-size: 0.75rem;
    padding: 0rem 0.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }

  &.chip-lg {
    font-size: 0.875rem;
    padding: 0rem 0.75rem;
    height: 2.125rem;
    line-height: 2.125rem;
    border-radius: 1rem;
  }
}

.app-filter-bar {
  $app-filter-bar-height: 2.5rem;
  display: flex;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 5px;
  background: white;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  //margin-right: 10px;

  font-weight: normal;
  height: $app-filter-bar-height;
  line-height: $app-filter-bar-height;
  font-size: 0.8rem;

  > div:first-child {
    padding: 0px 10px;
  }

  .openFilter {
    padding: 0px 10px;
    border-left: 1px solid #eee;
  }

  .content {
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    // display: grid;
    // grid-auto-flow: row;
    // grid-template-columns: repeat(10, auto);

    align-items: stretch;

    height: $app-filter-bar-height;
    line-height: $app-filter-bar-height;
    overflow-y: hidden;

    > * {
      border-left: 1px solid #eee;
      padding: 0px 10px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        background: #e0f8ff;
        color: #1ca0da;
      }
      &.hide {
        display: none;
      }
      &.disabled {
        color: #ccc;
        pointer-events: none;
      }

      &.selected {
        background: #1ca0da;
        color: white;
        font-weight: bold;
      }
    }
  }

  &.no-shrink {
    .content {
      flex-wrap: nowrap;
    }
  }
}

////////////////////////////////////////////////////////////////////////
///
///
/// table
///
///
////////////////////////////////////////////////////////////////////////

// for backward compatibility
.datatable-row {
  &:hover {
    background-color: #f9f9f9;
  }

  .floating-buttons {
    text-align: right;

    button {
      margin-left: 5px;
      opacity: 0;
    }
  }

  &:hover {
    background-color: #f9f9f9;

    .floating-buttons {
      button {
        opacity: 1;
      }
    }
  }

  .datatable-select {
    width: 30px;
  }

  &.datatable-row-disabled {
    background-color: #f0f0f0;
  }
  .datatable-row-disabled {
    background-color: #f0f0f0;
  }

  &.datatable-new {
    background-color: #fff8f0;
  }
}

// table sticky column
.mat-mdc-table-sticky-border-elem-right {
  border-left: 1px solid #eee;
}

.mat-mdc-table-sticky-border-elem-left {
  border-right: 1px solid #eee;
}

.mat-column-controls {
  width: 50px;
}

.app-table {
  border-collapse: separate;

  th {
    background-color: #fafafa !important;
  }

  &.scroll {
    max-width: 4000px;

    td,
    th {
      white-space: nowrap;

      .mat-sort-header-container {
        white-space: nowrap;
      }
    }

    tr {
      &.datatable-row {
        .floating-buttons {
          // border-left: 1px solid #eee;
          background-color: #fcfcfc;

          button {
            opacity: 1;
          }
        }
      }
    }
  }

  tr {
    &:hover {
      background-color: #f9f9f9;
    }

    td.floating-buttons {
      text-align: right;

      button {
        margin-left: 5px;
        opacity: 0;
      }
    }

    &:hover {
      td.floating-buttons {
        button {
          opacity: 1;
        }
      }
    }

    &.row-disabled {
      background-color: #ccc;
    }

    &.row-selected {
      background-color: #eee;
    }

    &.row-new {
      background-color: #f0f0f0;
    }
  }

  .rowCount {
    color: var(--viz-brown);
  }

  &.styleHeader {
    th {
      background-color: #ccc !important;
    }
  }

  &.styleOddEven {
    tr:nth-child(odd) {
      background-color: #ccc !important;
    }

    tr:nth-child(even) {
      background-color: #fff !important;
    }
  }

  //cell content style
  th,
  td {
    // align cell content
    &.leftCol {
      .mat-sort-header-container {
        justify-content: left;
      }

      text-align: left;
    }

    &.rightCol {
      .mat-sort-header-container {
        justify-content: right;
      }

      text-align: right;
    }

    &.centerCol {
      .mat-sort-header-container {
        justify-content: center;
      }

      text-align: center;
    }

    &.numCol {
      .mat-sort-header-container {
        justify-content: center;
      }

      text-align: center;
      padding-right: 14px;
    }

    .dateText {
      font-size: 0.8rem;

      //font-weight: 300;
      .timeText {
        font-size: 0.7rem;
        line-height: 0.7rem;
        //color: #707070;
        color: #aaa;
      }
    }
  }

  .smallText {
    color: #707070;
    font-size: small;
  }

  //override
  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }

  .datatable-row {
    &:hover {
      background-color: #f9f9f9;
    }

    .floating-buttons {
      text-align: right;

      button {
        margin-left: 5px;
        opacity: 0;
      }
    }

    &:hover {
      background-color: #f9f9f9;

      .floating-buttons {
        button {
          opacity: 1;
        }
      }
    }
  }

  th.datatable-select,
  td.datatable-select {
    width: 30px;
  }

  .datatable-row-disabled {
    background-color: #ccc;
  }

  .datatable-new {
    background-color: #f0f0f0;
  }
}

////////////////////////////////////////////////
/// utilies
///
.noscroll {
  overflow: hidden;
}

.max-height {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  &.mat-mdc-dialog-content {
    display: flex !important;
  }
}

.max-scroll {
  flex: 1 1 auto;
  overflow: auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.spacer {
  flex: 1 1 auto;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

//replaced by noscroll
// .tt_image_viewer_body {
//   height: 100%;
//   overflow: hidden;
// }

///////////////////////////
// respinsive utilities
///////////////////////////

@media screen and (max-width: $mobile-width) {
  .responsive_desktop {
    display: none !important;
  }

  .responsive_mobile {
  }
}

@media screen and (min-width: $mobile-width) {
  .responsive_desktop {
  }

  .responsive_mobile {
    display: none !important;
  }
}

@mixin devices($breakpoint) {
  //the name of the mixin is devices

  @if $breakpoint ==mobile {
    @media only screen and (max-width: $mobile-width) {
      @content;
    }
  }

  @if $breakpoint ==desktop {
    @media only screen and (min-width: $mobile-width) {
      @content;
    }
  }
}
